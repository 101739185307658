////////////////////////////////////////////////////////////
//  Program: Template List.jsx                            //
//  Application:  Template  List                          //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-03-31                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Row, Col } from "react-bootstrap";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import MroService from "../../services/MroService";
import PropertyService from "../../services/PropertyService";
import TrackeService from "../../services/TrackeService";
import back from "./../../assets/images/back.svg";
import ReactDatatable from "@ashvin27/react-datatable";
import exportIcon from "./../../assets/images/export-icon.svg";
import ChecklistService from "../../services/ChecklistService";

export class EquipmentTracker extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const firstDayOfMonth = Moment().subtract(6, 'months').toDate();
    const lastDayOfMonth = new Date();
    this.state = {
      is_month_wise: false,
      TrackerList: [],
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      start_date: firstDayOfMonth,
      end_date: lastDayOfMonth,
      currentYear: new Date().getFullYear(),
      property_slug: "",
      selectedPropertyList: [],
      propertySlug: "",
      propertyList: [],
      zone_type: "",
      zoneTypeList: [],
      selectedZoneTypeList: [],
      location_slug: "",
      locationList: [],
      selecteLocationList: [],
      records: [],
      trackerTypeText: "",
      trackerType: "",
      propertyLable: "",
      isFilterTrue: false,
      config: {
        key_column: "unique_slug",
        show_info: false,
        per_page: false,
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        pagination: "advance",
        language: {
          no_data_text: "No record found",
        },
      },

      extraButtons: [],
      config2: {
        sort: {
          column: "created_at",
          order: "desc",
        },
        key_column: "unique_slug",
        show_info: false,
        per_page: false,
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        pagination: "advance",
        language: {
          no_data_text: "No record found",
        },
      },

      extraButtons2: [],
      columns: [
        {
          key: "zone",
          text: "Equipment",
          className: "hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.name ? record.name : "";
          },
        },
        {
          key: "Jan",
          text: "Jan",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.jan ? record.jan : "";
          },
        },
        {
          key: "Feb",
          text: "Feb",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.feb ? record.feb : "";
          },
        },
        {
          key: "Mar",
          text: "Mar",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.march ? record.march : "";
          },
        },
        {
          key: "Apr",
          text: "Apr",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.april ? record.april : "";
          },
        },
        {
          key: "May",
          text: "May",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.may ? record.may : "";
          },
        },
        {
          key: "Jun",
          text: "Jun",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.june ? record.june : "";
          },
        },
        {
          key: "Jul",
          text: "Jul",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.july ? record.july : "";
          },
        },
        {
          key: "Aug",
          text: "Aug",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.aug ? record.aug : "";
          },
        },
        {
          key: "Sep",
          text: "Sep",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.sep ? record.sep : "";
          },
        },
        {
          key: "Oct",
          text: "Oct",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.oct ? record.oct : "";
          },
        },
        {
          key: "Nov",
          text: "Nov",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.nov ? record.nov : "";
          },
        },
        {
          key: "Dec",
          text: "Dec",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.dec ? record.dec : "";
          },
        },
      ],
      columns2: [
        {
          key: "equipment_name",
          text: "Equipment",
          className: "Room",
          sortable: true,
          cell: (record) => {
            return record.equipment_name ? record.equipment_name : "";
          },
        },
        {
          key: "inspection_no",
          text: "Inspection No",
          className: "Room",
          sortable: true,
          cell: (record) => {
            return record.inspection_no ? "#" + record.inspection_no : "";
          },
        },
        {
          key: "full_name",
          text: "Performed by",
          className: "technician",
          sortable: true,
          cell: (record) => {
            return record.full_name 
              ? record.full_name
              : "";
          },
        },
        {
          key: "completed_on",
          text: "Date",
          className: "date",
          sortable: true,
          cell: (record) => {
            return record.completed_on
              ? Moment(record.completed_on).format("DD MMM Y")
              : "";
          },
        },
        {
          key: "checklist_name",
          text: "Checklist",
          className: "technician",
          sortable: true,
          cell: (record) => {
            return record.checklist_name 
              ? record.checklist_name
              : "";
          },
        },
        {
          key: "Inspection",
          text: "Inspection",
          className: "Inspection",
          sortable: false,
          cell: (record) => {
            if (record.slug) {
              let slug = record.slug;
              return <a href={`/inspection/update/${slug}`} target="_blank" className="hand-cursor" rel="noopener noreferrer">Yes</a>;
            } else {
              return "No";
            }
          },
        },
      ],
    };
    this.filterToggle = this.filterToggle.bind(this);
    this.insPdfGenerator = this.insPdfGenerator.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetData = this.resetData.bind(this);
  }

  async insPdfGenerator(queryString = "") {
    this.setState({ loadingexport: true });
    var list = [];
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterEqpData = JSON.parse(localStorage.getItem("eqpLocationTracker"));
    let filterPropertyData = JSON.parse(localStorage.getItem("globleTrackerProperty"));
    if (filterData) {
      queryString =
        "start_date=" +
        Moment(filterData.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        (filterData.end_date
          ? Moment(filterData.end_date).format("YYYY-MM-DD")
          : "") +
        "&property_slug=" +
        (filterPropertyData?.property_slug ? filterPropertyData.property_slug : this.state.propertyList[0].value) +
        "&zone_type_id=" +
        (filterEqpData && filterEqpData.zone_type_id ?   filterEqpData.zone_type_id : "") +
        "&location_slug=" +
        (filterEqpData && filterEqpData.location_slug ? filterEqpData.location_slug : "") +
        "&zone_name=" +
        (filterEqpData && filterEqpData.zone_name ? filterEqpData.zone_name : "") +
        "&checklist_id=" +
        (filterData && filterData.checklist_id ? filterData.checklist_id : "") +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&is_pdf=" +
        1 +
        "&is_month_wise=" +
        (this.state.is_month_wise ? 1 : 0);
    } else {
      if (this.state.propertyList.length > 0) {
        var firstProperty = filterPropertyData 
        ? { 
            label: filterPropertyData.property_name, 
            value: filterPropertyData.property_slug 
          }
        : this.state.propertyList[0];
        }
      queryString =
       "start_date=" +
        Moment(this.state.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        (this.state.end_date ? Moment(this.state.end_date).format("YYYY-MM-DD") : "") +
        "&property_slug=" + firstProperty.value +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&is_pdf=" +
        1 +
        "&is_month_wise=" +
        (this.state.is_month_wise ? 1 : 0);
    }
    let res = await TrackeService.getEquipTrackerData(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.report["0"] ? res.data.report["0"] : [];
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loadingexport: false,
      list: list,
    });
    window.open(this.state.list.filePath, "_blank");
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  componentDidMount() {
    this.globalDataSet();
    this.getPropertyList();
    this.getCheckList();
    this.setState({ zoneTypeList: global.zoneTypeList });
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterEqpData = JSON.parse(localStorage.getItem("eqpLocationTracker"));
    if (
      filterData &&
      filterData.property_slug != undefined &&
      filterData.property_slug != ""
    ) {
      this.getLocation(
        "property_slug=" +
          filterData.property_slug
      );
      if (filterEqpData &&
        filterEqpData.location_slug != undefined &&
        filterEqpData.location_slug != "" &&
        filterEqpData.zone_name != undefined &&
        filterEqpData.zone_name != ""
      ) {
        this.setState({
          selecteLocationList: {
            label: filterEqpData.zone_name,
            value: filterEqpData.location_slug,
            zone_type_id: filterEqpData.zone_type_id,
          },
        });
      }
    }
  }
  globalDataSet = () => {
    var singleUser =
      localStorage.getItem("activePropertyCount") != "" &&
      localStorage.getItem("activePropertyCount") != undefined
        ? parseInt(localStorage.getItem("activePropertyCount"))
        : "";
    if (singleUser === 1) {
      var singlePropertyName = localStorage.getItem("singlePropertyName");
      var singlePropertySlug = localStorage.getItem("singlePropertySlug");
      const selectedStartDate = Moment().subtract(6, 'months').toDate();
      const selectedEndDate = new Date();
      var filterValue =
        '{"start_date" :"' +
        selectedStartDate +
        '","end_date" :"' +
        selectedEndDate +
        '", "property_slug":"' +
        singlePropertySlug +
        '", "property_name":"' +
        singlePropertyName +
        '"}';
      localStorage.setItem("globleTracker", filterValue);
    }
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }
  async getTrackerData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterEqpData = JSON.parse(localStorage.getItem("eqpLocationTracker"));
    let filterPropertyData = JSON.parse(localStorage.getItem("globleTrackerProperty"));
    const startDate = filterData 
      ? Moment(filterData.start_date).toDate() 
      : this.state.start_date 
      ? Moment(this.state.start_date).toDate() 
      :  Moment().subtract(6, 'months').toDate();
    const endDate = filterData
      ? Moment(filterData.end_date).toDate() 
      : this.state.end_date 
      ? Moment(this.state.end_date).toDate() 
      : new Date();
    if (filterData) {
      queryString =
        "start_date=" +
        Moment(startDate).format("YYYY-MM-DD") +
        "&end_date=" +
        Moment(endDate).format("YYYY-MM-DD") +
        "&property_slug=" +
        (filterPropertyData?.property_slug 
          ? filterPropertyData?.property_slug 
          : this.state.propertyList.length > 0 
          ? this.state.propertyList[0].value 
          : '' ) +
        "&zone_type_id=" +
        (filterEqpData && filterEqpData.zone_type_id ?   filterEqpData.zone_type_id : "")+
        "&location_slug=" +
        (filterEqpData && filterEqpData.location_slug ? filterEqpData.location_slug : "") +
        "&zone_name=" +
        (filterEqpData && filterEqpData.zone_name ? filterEqpData.zone_name : "") +
        "&checklist_id=" +
        (filterData && filterData.checklist_id ? filterData.checklist_id : "") +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&is_month_wise=" +
        (this.state.is_month_wise ? 1 : 0);
        if (filterPropertyData) {
          var selectedPropertyList = {
            label: filterPropertyData.property_name,
            value: filterPropertyData.property_slug,
          };
        var selectedCheckList = filterData.selectedCheckList;
        this.setState({
          ppropertySlug: filterPropertyData?.property_slug,
          propertyLable: filterPropertyData?.property_name,
          start_date: startDate,
          end_date: endDate,
          selectedPropertyList: selectedPropertyList,
          zone_name: filterEqpData ? filterEqpData.zone_name : "",
          location_slug: filterEqpData ? filterEqpData.location_slug : "",
          zone_type_id: filterEqpData ? filterEqpData.zone_type_id : "",
          selectedCheckList: selectedCheckList,
          checklist_id: filterData ? filterData.checklist_id : "",  
        });
      }
    } else {
      if (this.state.propertyList.length > 0) {
        const firstProperty = filterPropertyData 
        ? { 
            label: filterPropertyData.property_name, 
            value: filterPropertyData.property_slug 
          }
        : this.state.propertyList[0];
        this.setState({
            selectedPropertyList: firstProperty,
            propertySlug: firstProperty.value,
            propertyLable: firstProperty.label,
        });
      queryString =
       "start_date=" +
        Moment(this.state.start_date).format("YYYY-MM-DD") +
        "&end_date=" +
        (this.state.end_date ? Moment(this.state.end_date).format("YYYY-MM-DD") : "") +
        "&property_slug=" + firstProperty.value +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&is_month_wise=" +
        (this.state.is_month_wise ? 1 : 0);
        this.getLocation("property_slug=" + firstProperty.value);
      } else {
          queryString =
              "start_date=" +
              Moment(this.state.start_date).format("YYYY-MM-DD") +
              "&end_date=" +
              (this.state.end_date ? Moment(this.state.end_date).format("YYYY-MM-DD") : "") +
              "&sort_column=" +
              (this.state.sort_column ? this.state.sort_column : "") +
              "&sort_order=" +
              (this.state.sort_order ? this.state.sort_order : "");
      }
    }
    this.setState({ records: [] });
    let res = await TrackeService.getEquipTrackerData(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
    }
    if (this.state.checkFilter === true) {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
    });
    if (list.length > 0) {
      this.setState({
        completed_on: Moment(list[0].completed_on).format("DD MMM, YY"),
      });
    }
  }
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
      this.getLocation(
        "property_slug=" + value.value
      );
    this.setState({
      selectedZoneTypeList: "",
      selecteLocationList: "",
      trackerTypeText: "",
      trackerType: "",
      selectedPropertyList: value,
      property_slug: value.value,
      propertySlug: value.value,
      propertyLable: value.label,
      singleScreen:
        localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== ""
          ? true
          : false,
      singleScreenProperty: value.label,
    });
  }

  // Get Location List
  async getLocation(queryString = "") {
    this.setState({ selecteLocationList: [] });
    this.setState({ loading: true });
    var locationList = [];
    var locationListData = [];
    let res = await MroService.getLocationsMobile(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationList =
        res && res.data.data ? res.data.data : res.data ? res.data : [];
    }
    locationList.forEach((item) => {
      locationListData.push({
        label: item.name,
        value: item.slug,
        zone_type_id: item.zone_type_id,
      });
    });
    this.setState({
      locationList: locationListData,
    });
    this.setState({ loading: false });
  }

  async getCheckList(queryString = "") {
    var list = [];
    var listData = [];
    let res = await ChecklistService.getCheckList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
    }
    list.forEach((item) => {
      listData.push({
        label: item.checklist_name,
        value: item.slug,
        checklist_id : item.checklist_id,
      });
    });
    this.setState({
      checkList: listData,
    });
  }

  //handleChangeCheckList
  handleChangeCheckList(value) {
    let checklist_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        checklist_slug.push(item.value);
      });
    }
    this.setState({
      selectedCheckList: value,
      checklist_slug: value.value,
      checklist_id: value.checklist_id,
    });
  }

  handleChangeLocation(value) {
    let location_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_slug.push(item.value);
      });
    }
    this.setState({
      selecteLocationList: value,
      location_slug: value.value,
      zone_type_id: value.zone_type_id,
      zone_name: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  resetData() {
    const filterPropertyData = JSON.parse(localStorage.getItem("globleTrackerProperty"));
    const selectedProperty = filterPropertyData
        ? {
            label: filterPropertyData.property_name,
            value: filterPropertyData.property_slug
        }
        : this.state.propertyList.length > 0
        ? this.state.propertyList[0]
        : null;
    this.setState({
      propertyList: "",
      selectedPropertyList: "",
      trackerTypeText: "",
      trackerType: "",
      propertySlug: "",
      propertyLable: "",
      start_date: Moment().subtract(6, 'months').toDate(),
      end_date: new Date(),
      selectedPropertyName: "",
      singleScreenProperty: "",
      singleScreen: false,
      selectStartDate: "",
      selectEndDate: "",
      location_slug: "",
      zone_type_id: "",
      zone_name: "",
      selectedCheckList: "",
      checklist_id: "",
      checkFilter : false,
    });
    localStorage.removeItem("globleTracker");
    localStorage.removeItem("eqpLocationTracker");
    var GlobalApply =
      localStorage.getItem("outSidePorpertySlug") != "" &&
      localStorage.getItem("outSidePorpertySlug") != undefined
        ? parseInt(localStorage.getItem("outSidePorpertySlug"))
        : "";
    if (GlobalApply != undefined && GlobalApply != "") {
      const selectedStartDate = Moment().subtract(6, 'months').toDate();
      const selectedEndDate = new Date();
      var filterValue =
        '{"start_date":"' +
        selectedStartDate +
        '", "end_date":"' +
        selectedEndDate +
        '", "property_slug":"' +
        localStorage.getItem("outSidePorpertySlug") +
        '", "property_name":"' +
        localStorage.getItem("selectedPropertyName") +
        '"}';
      localStorage.setItem("globleTracker", filterValue);
    }
    if (
      localStorage.getItem("activePropertyCount") != "" &&
      localStorage.getItem("activePropertyCount") != undefined
    ) {
      this.globalDataSet();
    }

    let queryString =
    "start_date=" +
    this.state.start_date +
    "&end_date=" +
    this.state.end_date +
    (selectedProperty ? "&property_slug=" + selectedProperty.value : "");
    this.getTrackerData(queryString);
    this.getPropertyList().then(() => {
      if (selectedProperty) {
          this.setState({
              selectedPropertyList: selectedProperty,
              propertySlug: selectedProperty.value,
              propertyLable: selectedProperty.label
          });
      }
    });
  }

  customFilter() {
    const selectedStartDate = Moment(this.state.start_date).format(
      "YYYY-MM-DD"
    );
    let selectedEndDate = "";
    if (this.state.end_date && !isNaN(new Date(this.state.end_date))) {
      selectedEndDate = Moment(this.state.end_date).format("YYYY-MM-DD");
    }
    let queryString =
      "start_date=" +
      selectedStartDate +
      "&end_date=" +
      selectedEndDate +
      "&property_slug=" +
      this.state.propertySlug +
      "&zone_type_id=" +
      (this.state.zone_type_id ? this.state.zone_type_id : "") +
      "&location_slug=" +
      (this.state.location_slug ? this.state.location_slug : "") +
      "&zone_name=" +
      (this.state.zone_name ? this.state.zone_name : "") +
      "&checklist_id=" +
      (this.state.checklist_id ? this.state.checklist_id : "");
    this.setState({ tableQueryString: "", customQueryString: queryString });
    var filterValue =
      '{"start_date" :"' +
      selectedStartDate +
      '", "end_date" :"' +
      selectedEndDate +
      '"' +
      (this.state.selectedCheckList ? ', "selectedCheckList" :' + JSON.stringify(this.state.selectedCheckList) : "") +  
      (this.state.checklist_id ? ', "checklist_id" :"' + this.state.checklist_id + '"' : "") +  
      '}';
    if(this.state.location_slug)
      {
        var filterValueLocation =
          (this.state.zone_type_id
            ? '{"zone_type_id" :"' + this.state.zone_type_id
            : "") +
          (this.state.location_slug
            ? '", "location_slug" :"' + this.state.location_slug
            : "") +
          (this.state.zone_name ? '", "zone_name" :"' + this.state.zone_name : "") +
            '"}';
        localStorage.setItem("eqpLocationTracker", filterValueLocation);
      }
    localStorage.setItem("globleTracker", filterValue);
    localStorage.setItem("globleTrackerProperty", JSON.stringify({
      property_slug: this.state.propertySlug,
      property_name: this.state.propertyLable,
    }));
    this.setState({ globalTrackerState: filterValue });
    this.setState({ isFilterTrue: true, checkFilter : true });
    this.getTrackerData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }
  // Get Property List
  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });
    if (propertyListData.length > 0) {
      this.setState({
        propertyList: propertyListData,
        selectedPropertyList: propertyListData[0], // Set the first property as selected by default
        property_slug: propertyListData[0].value,
        propertySlug: propertyListData[0].value,
        propertyLable: propertyListData[0].label,
      }, () => {
        const filterPropertyData = JSON.parse(localStorage.getItem("globleTrackerProperty"));
        const propertySlug = filterPropertyData?.property_slug 
            ? filterPropertyData.property_slug 
            : propertyListData.length > 0 
            ? propertyListData[0].value 
            : '';
        if (propertySlug) {
            this.getLocation("property_slug=" + propertySlug);
        }
        
      });
    } else {
      this.setState({ propertyList: propertyListData });
    }
    this.getTrackerData();
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }
    console.log(data);
    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getTrackerData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.setState(
        { 
          sort_order: data.sort_order.order, // Correct the mapping (column is order, order is column)
          sort_column: data.sort_order.column 
        }, 
        () => {
          // This callback ensures that getTrackerData runs after the state is updated
          this.getTrackerData(queryString);
        }
      );
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    if (data.slug !== undefined) {
      window.open("inspection/update/" + data.slug, "_blank");
    }
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main className="p-0">
          <div className="container-fluid p-0">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle tracker_btn_new">
                    <div className="btnNavigate d-flex justify-content-end">
                      <Button
                        onClick={this.insPdfGenerator}
                        className="gf-primary-btn-small-one mg-l-7 loader_btn align-items-center export_pdf_btn"
                        disabled={this.state.records.length === 0 ? true : false}
                      >
                        <img src={exportIcon} alt="" />
                        {this.state.loadingexport ? global.loader : "Export PDF"}
                      </Button>
                      <button
                        onClick={this.filterToggle}
                        className="gf-primary-btn-small-one mg-l-7"
                      >
                        {this.state.showFilter === 1 ? "Hide Filter" : "Filter"}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    {this.state.showFilter ? (
                      <Row className="align-items-start fliter_back">
                        <div className="order_form">
                          <div className="d-flex justify-content-between align-items-center w-100 ">
                            <h3
                              className="hand-cursor back-size"
                              onClick={this.filterToggle}
                            >
                              <img src={back} alt="" />
                              Back
                            </h3>
                          </div>

                          <h3>Filter</h3>
                          <Row className="align-items-start justify-content-between m-0 p-0">
                            <label className="form-label text-left mg-b-5 mt-0">
                              Start Date
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <DatePicker
                                className="form-control max_width_100"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="start_date"
                                placeholderText="dd-mm-yy"
                                dateFormat="dd MMM yy"
                                selected={this.state.start_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "start_date",
                                      value: data,
                                    },
                                  })
                                }
                                maxDate={this.state.end_date}
                              />
                            </Col>
                            <label className="form-label text-left mg-b-5">
                              End Date
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <DatePicker
                                className="form-control max_width_100"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="end_date"
                                placeholderText="dd-mm-yy"
                                dateFormat="dd MMM yy"
                                selected={this.state.end_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "end_date",
                                      value: data,
                                    },
                                  })
                                }
                                minDate={this.state.start_date}
                              />
                            </Col>
                            <label className="form-label text-left mg-b-5">
                              Property
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <Select
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedPropertyList}
                                options={this.state.propertyList}
                                onChange={(value) =>
                                  this.handleChangeProperty(value)
                                }
                                defaultValue={this.state.selectedPropertyList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              Checklist{" "}
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedCheckList}
                              options={this.state.checkList}
                              onChange={(value) =>
                                this.handleChangeCheckList(value)
                              }
                              defaultValue={this.state.selectedCheckList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                            </Col>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <label className="form-label text-left mg-b-5">
                                Location{" "}
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selecteLocationList}
                                options={this.state.locationList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeLocation(value)
                                }
                                defaultValue={this.state.selecteLocationList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                {this.state.isFilterTrue
                                  ? global.loader
                                  : "Search"}
                              </button>
                            </Row>
                          </Row>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid p-0">
              <div className="mgb-neg-20 brandList dashboardRightcard">
                <div className="row">
                  <div className="col-md-6 col-lg-6 d-flex align-items-center justify-content-start">
                    <label className="form-label mg-b-0">
                      {this.state.is_month_wise ? "Monthly View" : "Yearly View"}{" "}
                    </label>
                    <div
                      className={
                        this.state.is_month_wise ? "az-toggle on" : "az-toggle"
                      }
                      onClick={() => {
                        // Toggle the state
                        this.setState(
                          {
                            is_month_wise: !this.state.is_month_wise,
                          },
                          () => {
                            // Call the API after the state has been updated
                            this.getTrackerData();
                          }
                        );
                      }}
                    >
                      <span></span>
                    </div>
                  </div>
                  <div className="col-md-12">
                  {this.state.start_date !== undefined &&
                    this.state.start_date !== "" ? (
                      <>
                        <div className="checklist_table_heading">
                          <span className="lspan">Start Date: &nbsp;</span>
                          <span className="rspan">
                          {Moment(this.state.start_date).format("DD MMM YYYY")}
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.end_date !== undefined &&
                    this.state.end_date !== "" ? (
                      <>
                        <div className="checklist_table_heading">
                          <span className="lspan">End Date: &nbsp;</span>
                          <span className="rspan">
                          {Moment(this.state.end_date).format("DD MMM YYYY")}
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.propertyLable !== undefined &&
                    this.state.propertyLable !== "" ? (
                      <>
                        <div className="checklist_table_heading">
                          <span className="lspan">Search Applied On : </span>
                          <span className="rspan">
                            {this.state.propertyLable
                              ? this.state.propertyLable
                              : ""}
                               {this.state.selectedCheckList
                            ? ", " + this.state.selectedCheckList.label
                            : ""}
                            {this.state.zone_name
                              ? ", " + this.state.zone_name
                              : ""}
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {this.state.is_month_wise == 1 ? (
                  <ReactDatatable
                    className="table no-wrap mg-b-30 pd-b-2 table-bordered"
                    config={this.state.config}
                    records={this.state.records}
                    columns={this.state.columns}
                    extraButtons={this.state.extraButtons}
                    dynamic={true}
                    loading={this.state.loading}
                    total_record={this.state.total_record}
                    onChange={this.tableChangeHandler}
                    onRowClicked={this.rowClickedHandler}
                  />
                ) : (
                  <ReactDatatable
                    className="table no-wrap mg-b-30 pd-b-2 table-bordered"
                    config={this.state.config2}
                    records={this.state.records}
                    columns={this.state.columns2}
                    extraButtons={this.state.extraButtons2}
                    dynamic={true}
                    loading={this.state.loading}
                    total_record={this.state.total_record}
                    onChange={this.tableChangeHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default EquipmentTracker;
