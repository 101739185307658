////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Logout.jsx                                   //
//  Application: authentication                           //
//  Option: For logout                                    //
//  Developer: NP  						                            //
//  Date: 2021-12-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../../services/AuthenticationService'

export class Logout extends Component {
  constructor(props) {
      super(props)

      this.logout = this.logout.bind(this);
  }

  componentDidMount() {
      this.logout();
  }

  logout = async (e) => {
    var localInsp = localStorage.getItem('localInsp') != "" ? localStorage.getItem('localInsp') : "";
    
    if (global.headerToken) {
      /* Insp storage not clear */
          await AuthenticationService.logout();
      localStorage.removeItem("sessionExpiry");
      localStorage.removeItem("headerToken");
      localStorage.removeItem("customSpinnerSession");
      localStorage.removeItem("properties");
      localStorage.removeItem("activePropertyCount");
      localStorage.removeItem("userData");
      localStorage.removeItem("permissions");
      localStorage.removeItem("auditCategory");
      localStorage.removeItem("audit_name_audit_resume");
      localStorage.removeItem("property_name_audit_resume");
      localStorage.removeItem("property_slug_audit_resume");
      localStorage.setItem("localInsp", localInsp);
      localStorage.removeItem("actionItemFilterState");
      localStorage.removeItem("auditFilterState");
      localStorage.removeItem("checklistFilterState");
      localStorage.removeItem("dailyLogFilterState");
      localStorage.removeItem("inspectionFilterState");
      localStorage.removeItem("mroFilterState");
      localStorage.removeItem("propertyFilterState");
      localStorage.removeItem("userFilterState");
      this.props.history.push('/signin');
      localStorage.removeItem("globleTracker");
      localStorage.removeItem("roomLocationTracker");
      localStorage.removeItem("eqpZoneLocationTracker");
      localStorage.removeItem("eqpLocationTracker");
      localStorage.removeItem("comLocationTracker");
      localStorage.removeItem("mroTrackerLocation");
      localStorage.removeItem("globleTrackerProperty");
      // localStorage.clear();
      // sessionStorage.clear();
      // await this.localStorageSet();
      // this.props.history.push('/signin');
      
    } else {
      localStorage.removeItem("sessionExpiry");
      localStorage.removeItem("headerToken");
      localStorage.removeItem("customSpinnerSession");
      localStorage.removeItem("properties");
      localStorage.removeItem("activePropertyCount");
      localStorage.removeItem("userData");
      localStorage.removeItem("permissions");
      localStorage.setItem("localInsp", localInsp);
      localStorage.removeItem("actionItemFilterState");
      localStorage.removeItem("auditFilterState");
      localStorage.removeItem("checklistFilterState");
      localStorage.removeItem("dailyLogFilterState");
      localStorage.removeItem("inspectionFilterState");
      localStorage.removeItem("mroFilterState");
      localStorage.removeItem("propertyFilterState");
      localStorage.removeItem("userFilterState");
      localStorage.removeItem("globleTracker");
      localStorage.removeItem("roomLocationTracker");
      localStorage.removeItem("eqpZoneLocationTracker");
      localStorage.removeItem("eqpLocationTracker");
      localStorage.removeItem("comLocationTracker");
      localStorage.removeItem("mroTrackerLocation");
      localStorage.removeItem("globleTrackerProperty");
        sessionStorage.clear();
        this.props.history.push('/signin');
      }
  }
  render() {
    return (
      <div></div>
    )
  }

}

export default Logout
